import {arrayToOptions} from './array-to-options.js'

const years = ['2021', '2022', '2023', '2024', '2025']
const times = ['Yearly', 'Quarterly', 'Monthly']
const orderTypes = ['Hold', 'Worksheet', 'PO', 'In Progress', 'Shipped']
const reportTypes = ['Sales', 'Inventory']
const reportTypesSuperAdmin = reportTypes.concat('VPO')
const inventoryTypes = ['ATS + Future ATS', 'ATS', 'Future ATS', 'On Hand', 'RTS', 'Future Stock']
const summaryPage = ['Units', 'Dollars']
const inSeason = ['In Season', 'Upfront', 'Missing']
const inSeasonWithAll = ['All', 'In Season', 'Upfront']
const margin = ['Low', 'Avg', 'Good', 'Great']

const yearOptions = arrayToOptions(years)
const timeOptions = arrayToOptions(times)
const orderTypeOptions = arrayToOptions(orderTypes)
const reportTypeOptions = arrayToOptions(reportTypes)
const reportTypesSuperAdminOptions = arrayToOptions(reportTypesSuperAdmin)
const inventoryTypeOptions = arrayToOptions(inventoryTypes)
const summaryPageOptions = arrayToOptions(summaryPage)
const inSeasonOptions = arrayToOptions(inSeason)
const inSeasonOptionsWithAll = arrayToOptions(inSeasonWithAll)
const marginOptions = arrayToOptions(margin)

export {yearOptions, timeOptions, orderTypeOptions, reportTypeOptions, reportTypesSuperAdminOptions, inventoryTypeOptions, summaryPageOptions, marginOptions, inSeasonOptions, inSeasonOptionsWithAll}
